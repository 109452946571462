.admin-page {
	&__sidebar {
		.ant-layout-sider-trigger {
			background-color: #fff;

			> span > svg {
				color: black;
			}
		}
	}
}

@primary-color: #000000;@menu-collapsed-width: 40px;