.log-card {
	width: 100%;
	margin-bottom: 1em;

	&:hover {
		cursor: pointer;
	}

	&--active {
		border: 2px solid black !important;
	}

	&__download-icon {
		font-size: 32px;
	}
}

@primary-color: #000000;@menu-collapsed-width: 40px;