.messages-table {
	&__description-column {
		white-space: nowrap;
		max-width: 150px;
	}

	&__description-tooltip {
		overflow: hidden;
		text-overflow: ellipsis;
	}
}

@primary-color: #000000;@menu-collapsed-width: 40px;