.home-container {

    &__landing-section {
        background-color: black;
        color: white;
        min-height: 100vh;

        &__row {
            height: 100% !important;
        }

        &__title {
            font-weight: bold;
            font-size: 5em;
        }

        &__subtitle {
            font-weight: bold;
            font-size: 1.6em;
            margin-bottom: 50px;
        }

        &__description {
            font-weight: 400;
            margin-bottom: 50px;
        }

        &__divider {
            height: 1px;
            width: 50%;
            background-color: white;
        }

        &__text-section {
            height: 100% !important;
        }

        &__video-section {
            height: 100% !important;
        }
    }

    // Sekcja portfolio
    &__portfolio-section {
        background-color: black;
        color: white;
        padding-top: 80px;
        padding-bottom: 80px;

        &__title {
            font-weight: 400;
        }

        &__subtitle {
            font-weight: bold;
            font-size: 3em;
            margin-bottom: 30px;
        }

        &__description {
            margin-bottom: 50px;
        }

        &__button-section {
            font-weight: bold;
            margin-bottom: 50px;
            
            &__button {
                border-color: white !important;
                background-color: black !important;
                color: white !important;
                font-size: 1.2em !important;
                height: 50px !important;
                width: 200px;
            }

            &__button:focus, &__button:hover {
                background-color: white !important;
                color: black !important;
            }
        }


        .rec-dot {
            background-color: black !important;
            border-color: black !important;
            box-shadow: none;
        }

        .rec-arrow.rec-arrow-left {
            background-color: black !important;
            border-color: black !important;
            box-shadow: none;
        }

        .rec-arrow.rec-arrow-right {
            background-color: black !important;
            border-color: black !important;
            box-shadow: none;
        }

        &__portfolio-element {
            padding-left: 10px;
            padding-right: 10px;

            &__image {
                max-width: 100%;
            }

            &__title {
                margin-top: 20px;
                font-size: 2em;
                font-weight: bold;
            }

            &__subtitle {
                font-size: 1em;
                text-decoration: underline;
            }

            &__description {
                margin-top: 20px;
                font-size: 1em;
            }
        }

    }

    // Sekcja z newsami
    &__news-section {
        background-color: black;
        color: white;

        &__title {
            font-weight: 400;
        }

        &__subtitle {
            font-weight: bold;
            font-size: 3em;
            margin-bottom: 30px;
        }

        &__description {
            margin-bottom: 50px;
        }
    }

    &__news-section-carousel {
        background-color: black; 
        padding-bottom: 100px;

        .rec-dot {
            background-color: black !important;
            border-color: black !important;
            box-shadow: none;
        }

        .rec-arrow.rec-arrow-left {
            background-color: black !important;
            border-color: black !important;
            box-shadow: none;
        }

        .rec-arrow.rec-arrow-right {
            background-color: black !important;
            border-color: black !important;
            box-shadow: none;
        }


        &__image-column {
            &__image {
                // max-height: 300px;
                max-width: 100%;
            }
        }

        &__text-column {
            background-color: white;

            &__row {
                height: 100%;
                padding-left: 20px;
                padding-right: 20px;

                &__date {
                    margin-top: 10px;
                    color: rgba(0, 0, 0, 0.479);
                }

                &__title {
                    margin-top: 10px;
                    font-size: 2em;
                    font-weight: bold;
                }

                &__description {
                    margin-top: 10px;

                }

                &__button {
                    margin-top: 30px;
                    margin-bottom: 30px;
                }
            }
        }

    }



    // Sekcja z ofertą
    &__services-section {
        padding-top: 80px;
        padding-bottom: 80px;

        &__title {
            font-weight: 400;
        }

        &__subtitle {
            font-weight: bold;
            font-size: 3em;
        }

        &__description {
            color: rgba(0, 0, 0, 0.5);
        }
    }

    // Sekcja z kafelkami ofert
    &__services-tiles-section {
        padding-bottom: 80px;

        &__tile {

            &__number {
                padding-left: 30px;
                font-weight: 400;
                color: white;
                margin-top: 50px;
            }

            &__title {
                padding-left: 30px;
                font-weight: bold;
                color: white;
                font-size: 1.8em;
                margin-top: 10px;
            }

            &__description {
                padding-left: 30px;
                padding-right: 30px;
                font-weight: 400;
                color: white;
                margin-top: 10px;
                margin-bottom: 300px;
            }

            &__1 {
                background-image: url("./assets/services-image-1.jpeg");
                background-position: center;
                background-size: cover;
            }

            &__2 {
                background-image: url("./assets/services-image-2.jpeg");
                background-position: center;
                background-size: cover;
            }

            &__3 {
                background-image: url("./assets/services-image-3.jpeg");
                background-position: center;
                background-size: cover;
            }

            &__4 {
                background-image: url("./assets/services-image-4.jpeg");
                background-position: center;
                background-size: cover;
            }

        }

    }

    // Sekcja z galerią
    &__gallery-section {
        padding-top: 80px;
        padding-bottom: 80px;

        &__image {
            width: 100%;
        }
    }

    // Sekcja z formą kontaktową
    &__contact-form-section {
        padding-top: 80px;
        padding-bottom: 80px;

        &__title {
            font-weight: bold;
            font-size: 2em;
            margin-bottom: 10px;
            justify-content: center;
        }

        &__description {
            font-weight: 400;
            margin-bottom: 50px;
            justify-content: center;
        }

        &__label {
            font-weight: bold;
            margin-bottom: 50px;
            justify-content: center;
        }

        &__input-section {
            font-weight: bold;
            margin-bottom: 50px;
            justify-content: center;

            &__input {
                width: 40% !important;
            }
        }

        &__button-section {
            font-weight: bold;
            margin-bottom: 50px;
            justify-content: center;
            
            &__button {
                border-color: black !important;
                background-color: black !important;
                color: white !important;
                font-size: 1.2em !important;
                height: 50px !important;
                width: 150px;
            }

            &__button:focus, &__button:hover {
                background-color: white !important;
                color: black !important;
            }
        }

    }

    // Sekcja z przekierowaniem o nas
    &__about-agency-section {
        background-color: #f2f2f2;
        padding-top: 80px;
        padding-bottom: 80px;

        &__title {
            font-weight: 400;
            margin-bottom: 20px;
            justify-content: center;
        }

        &__subtitle {
            font-weight: bold;
            margin-bottom: 20px;
            font-size: 2em;
            justify-content: center;
        }

        &__button-section {
            font-weight: 400;
            margin-bottom: 20px;
            justify-content: center;

            &__button {
                border-color: black !important;
                background-color: transparent !important;
                font-size: 1.2em !important;
                height: 50px !important;
                width: 150px;
            }

            &__button:focus, &__button:hover {
                background-color: black !important;
                color: white !important;
            }
        }

    }

    // Stopka
    &__footer-section {
        background-color: black;
        color: white;

        padding-top: 80px;
        padding-bottom: 80px;

        &__title {
            font-weight: 600;
            font-size: 1.2em;
            margin-bottom: 20px;
        }

        &__element {
            font-weight: 400;
            font-size: 1em;
            color: rgba(255, 255, 255, 0.726)
        }

        &__image {
            width: 80%;
        }
    }
}
@primary-color: #000000;@menu-collapsed-width: 40px;