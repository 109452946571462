.bg-site {
	background-color: white !important;
}

.content-layout {
	background: #fff;
	min-height: calc(100vh - 64px);
	width: 100%;
	flex-wrap: wrap;
	justify-content: center;
	align-items: flex-start;

	&-margin {
		margin-top: 64px;
	}
}

.w-fit {
	width: fit-content !important;
}

// stylowanie scrollbara
body::-webkit-scrollbar {
	width: 0.5em;
}

body::-webkit-scrollbar-track {
	border: 0.1px solid lightgray;
	border-radius: 0.25em;
}

body::-webkit-scrollbar-thumb {
	background-color: darkgrey;
	outline: 1px solid slategrey;
	border-radius: 0.25em;
}

// sticky header
.fixed-header {
	position: fixed;
	z-index: 1;
	width: 100%;
}

@primary-color: #000000;@menu-collapsed-width: 40px;