@import '~antd/lib/style/themes/default.less';

.user-details {
	&__lockout__badge {
		color: @gold-5 !important;
	}

	&__is-deleted__text {
		font-size: 1.5rem;
	}
}

@primary-color: #000000;@menu-collapsed-width: 40px;